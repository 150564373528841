import {gql} from '@apollo/client';

export default gql`
  query login($appName: String!, $email: String!, $password: String!) {
    login(appName: $appName, email: $email, password: $password) {
      sessionId
      apiKey
      firstName
      lastName
    }
  }
`;
