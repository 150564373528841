import React, {useCallback} from 'react';

import {Formik} from 'formik';

const Form = ({onSubmit, ...props}) => {
  const handleSubmit = useCallback(async (values, actions) => {
    await onSubmit(values);
    actions.resetForm({values});
  }, []);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
      {...props}
    />
  );
};

export default Form;
