import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Styled from './styles';

const useStyles = makeStyles(() => ({
  defaultLoadingContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-ms-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
  },
  inlineFlex: {
    display: 'inline-flex',
  },
  alignTop: {
    top: 16,
    transform: 'translateX(-50%)',
  },
}));

const LoadingIndicator = ({
  loading,
  transparent,
  fill,
  align,
  color,
  style,
  ...props
}) => {
  const classes = useStyles();

  if (!loading) {
    return null;
  }

  return (
    <Styled.Container
      transparent={transparent}
      className={clsx({
        [classes.defaultLoadingContainer]: fill,
        [classes.inlineFlex]: !fill,
        [classes.alignTop]: align === 'top',
      })}
      style={style}>
      <CircularProgress
        style={{
          color,
        }}
        {...props}
      />
    </Styled.Container>
  );
};

LoadingIndicator.defaultProps = {
  loading: true,
  color: '#aaa',
  size: 24,
};

LoadingIndicator.propTypes = {
  loading: PropTypes.bool,
};

export default LoadingIndicator;
