import {gql} from '@apollo/client';

export default gql`
  mutation createOrUpdateDataObjectItem(
    $createOrUpdateDataObjectItemInput: CreateOrUpdateDataObjectItemInput!
  ) {
    createOrUpdateDataObjectItem(input: $createOrUpdateDataObjectItemInput) {
      id
    }
  }
`;
