import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import * as Storage from '@macanta/utils/storage';
import {navigate} from 'gatsby';

const LogoutItem = () => {
  const handleLogout = () => {
    Storage.setItem('session', {sessionId: '', apiKey: ''});
    navigate('/', {replace: true});
  };

  return (
    <ListItem button onClick={handleLogout}>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary={'Log Out'} />
    </ListItem>
  );
};

export default LogoutItem;
