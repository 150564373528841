import React from 'react';
import {LIST_CONTACTS} from '@macanta/graphql/contacts';
import ContactsQuery, {
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT,
} from '@macanta/modules/hoc/ContactsQuery';
import DataTable from '@macanta/containers/DataTable';

const DEFAULT_COLUMNS = [
  {id: 'name', label: 'Name', minWidth: 170},
  {id: 'company', label: 'Company', minWidth: 170},
  {id: 'email', label: 'Email', minWidth: 170},
  {id: 'city', label: 'City', minWidth: 170},
  {id: 'postalCode', label: 'PostCode', minWidth: 170},
];

const getFullName = (firstName, lastName) => {
  return `${firstName} ${lastName}`.trim();
};

const ContactsSearchTable = ({
  searchQuery,
  onSelectItem,
  columns = DEFAULT_COLUMNS,
  ...props
}) => {
  console.log('ContactsSearchTable', props);

  const handleCompleted = (data, {client, variables}) => {
    console.log('ContactsSearchTable handleCompleted', variables);

    data?.listContacts?.items.forEach((item) => {
      // console.log('Writing contact data to LIST_CONTACTS query', item);
      client.writeQuery({
        query: LIST_CONTACTS,
        data: {
          listContacts: {
            __typename: 'ContactItems',
            items: [item],
            count: 1,
          },
        },
        variables: {
          q: item.id,
          page: DEFAULT_PAGE,
          limit: DEFAULT_PAGE_LIMIT,
        },
      });
    });
  };

  return (
    <ContactsQuery {...searchQuery} onCompleted={handleCompleted}>
      {({data = {}, loading}) => {
        const contactsWithFullName = data?.listContacts?.items.map((item) => ({
          name: getFullName(item.firstName, item.lastName),
          ...item,
        }));

        return (
          <DataTable
            fullHeight
            loading={loading}
            hideEmptyMessage={loading}
            columns={columns}
            selectable
            data={contactsWithFullName}
            onSelectItem={onSelectItem}
            {...props}
          />
        );
      }}
    </ContactsQuery>
  );
};

ContactsSearchTable.defaultProps = {};

export default ContactsSearchTable;
