import {gql} from '@apollo/client';

export default gql`
  query getAppSettings($appName: String!) {
    getAppSettings(appName: $appName) {
      uiColour
      customLogo {
        imageData
        filename
        type
      }
    }
  }
`;
