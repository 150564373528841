import {useQuery} from '@apollo/client';
import {LIST_DATA_OBJECT_TYPES} from '@macanta/graphql/dataObjects';

const useDOTypes = (options = {}) => {
  const {loading, data, error} = useQuery(LIST_DATA_OBJECT_TYPES, {
    ...options,
  });

  return {loading, data, error};
};

export default useDOTypes;
