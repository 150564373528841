import {gql} from '@apollo/client';

export default gql`
  mutation createOrUpdateDONote(
    $createOrUpdateDONoteInput: CreateOrUpdateDONoteInput!
  ) {
    createOrUpdateDONote(input: $createOrUpdateDONoteInput) {
      id
    }
  }
`;
