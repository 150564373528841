import React, {useState} from 'react';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import DrawerComp from '@material-ui/core/Drawer';
import HomeIcon from '@material-ui/icons/Home';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import WidgetsIcon from '@material-ui/icons/Widgets';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from '@material-ui/core/styles';
import CompanyLogo from '@macanta/modules/CompanyLogo';
import {getAppInfo} from '@macanta/utils/app';
import Tooltip from '@macanta/components/Tooltip';
import {useNavigate} from '@reach/router';
import LogoutItem from './LogoutItem';
import ExpandItem from './ExpandItem';
import * as Styled from './styles';

export const drawerWidth = 240;

export const getDrawerClosedWidth = (theme) =>
  `calc(${theme.spacing(7)} + 1px)`;

export const DEFAULT_SELECTED_TAB = 'dashboard';

const DEFAULT_GENERAL_TABS = [
  {id: 'dashboard', label: 'Dashboard', IconComp: HomeIcon},
  {
    id: 'workflowBoards',
    label: 'Workflow Boards',
    IconComp: DeveloperBoardIcon,
  },
  {id: 'widgets', label: 'Widgets', IconComp: WidgetsIcon},
  {id: 'admin', label: 'Admin', IconComp: SupervisorAccountIcon},
];

const DEFAULT_SUPPORT_TABS = [
  {id: 'support', label: 'Help & Support', IconComp: ContactSupportIcon},
  {id: 'knowledgeBase', label: 'Knowledge Base', IconComp: LibraryBooksIcon},
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    boxSizing: 'border-box',
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: getDrawerClosedWidth(theme),
    // [theme.breakpoints.up('sm')]: {
    //   width: `calc(${theme.spacing(9)} + 1px)`,
    // },
  },
  sidebarLogo: {
    width: getDrawerClosedWidth(theme),
  },
}));

const DrawerListItem = ({selectedItem, id, label, onSelectTab, IconComp}) => {
  const handleSelectTab = () => {
    onSelectTab(id);
  };

  return (
    <Tooltip title={label} placement="right">
      <Styled.ListItem
        selected={selectedItem === id}
        button
        onClick={handleSelectTab}>
        <ListItemIcon>{IconComp && <IconComp />}</ListItemIcon>
        <ListItemText primary={label} />
      </Styled.ListItem>
    </Tooltip>
  );
};

const Drawer = ({
  drawerOpen,
  onDrawerToggle,
  selectedTab = DEFAULT_SELECTED_TAB,
  ...props
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState(selectedTab);

  const [appName] = getAppInfo();

  const handleSelectTab = (type) => {
    if (type === 'dashboard') {
      setSelectedItem('dashboard');
      navigate(`/app/dashboard`);
    } else if (type === 'workflowBoards') {
      setSelectedItem('workflowBoards');
    } else if (type === 'widgets') {
      setSelectedItem('widgets');
    } else if (type === 'admin') {
      setSelectedItem('admin');
    } else if (type === 'support') {
      setSelectedItem('support');
    } else if (type === 'knowledgeBase') {
      // redirect to knowledge base link
    }
  };

  const drawerContent = (
    <Styled.DrawerContent {...props}>
      <Styled.ContentToolbarSpacer expanded={drawerOpen}>
        <CompanyLogo className={classes.sidebarLogo} />
        {drawerOpen && (
          <Styled.CompanyNameContainer>
            <Styled.CompanyName variant="h6">{appName}</Styled.CompanyName>
          </Styled.CompanyNameContainer>
        )}
      </Styled.ContentToolbarSpacer>
      <Divider />
      {/* <Styled.ContentToolbarSpacer /> */}
      <List>
        {DEFAULT_GENERAL_TABS.map((item) => {
          return (
            <DrawerListItem
              key={item.id}
              selectedItem={selectedItem}
              id={item.id}
              label={item.label}
              onSelectTab={handleSelectTab}
              IconComp={item.IconComp}
            />
          );
        })}
      </List>
      <Divider />
      <List>
        {DEFAULT_SUPPORT_TABS.map((item) => {
          return (
            <DrawerListItem
              key={item.id}
              selectedItem={selectedItem}
              id={item.id}
              label={item.label}
              onSelectTab={handleSelectTab}
              IconComp={item.IconComp}
            />
          );
        })}
      </List>
      <Divider />
      <List>
        <LogoutItem />
      </List>
      <Styled.BottomList>
        <ExpandItem expanded={drawerOpen} onClick={onDrawerToggle} />
      </Styled.BottomList>
    </Styled.DrawerContent>
  );

  return (
    <DrawerComp
      variant="permanent"
      onClose={onDrawerToggle}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: drawerOpen,
        [classes.drawerClose]: !drawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        }),
      }}>
      {drawerContent}
    </DrawerComp>
  );
};

export default Drawer;
