import React, {useMemo} from 'react';

import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import colors from '@macanta/themes/colors';
import * as applicationStyles from '@macanta/themes/applicationStyles';

const Theme = ({theme: customTheme, appSettings, children}) => {
  const primaryColor = appSettings.uiColour || colors.primary;
  const secondaryColor = `${primaryColor}aa`;
  const tertiaryColor = `${primaryColor}88`;
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(
    () =>
      createMuiTheme({
        spacing: applicationStyles.spacing,
        palette: {
          background: prefersDarkMode ? {} : {default: colors.background},
          primary: {
            main: prefersDarkMode ? secondaryColor : primaryColor,
            contrastText: colors.white,
          },
          secondary: {
            main: prefersDarkMode ? tertiaryColor : secondaryColor,
          },
          type: prefersDarkMode ? 'dark' : 'light',
          ...customTheme.palette,
        },
        typography: {
          htmlFontSize: 16,
          fontFamily: `"Fira Sans", "Helvetica", "Arial", sans-serif`,
        },
        components: {
          // Set default heights for html, body and gatsby outer elements
          MuiCssBaseline: {
            styleOverrides: {
              html: {
                height: '100%',
              },
              body: {
                height: '100%',
              },
              '#___gatsby': {
                height: '100%',
              },
              '#gatsby-focus-wrapper': {
                height: '100%',
              },
            },
          },
        },
      }),
    [prefersDarkMode, customTheme, primaryColor, secondaryColor, tertiaryColor],
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

Theme.defaultProps = {
  theme: {},
};

export default Theme;
