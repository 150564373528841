import React from 'react';
import * as Styled from './styles';

const Section = ({
  children,
  title,
  HeaderLeftComp,
  HeaderRightComp,
  FooterComp,
  fullHeight,
  style,
  headerStyle,
  bodyStyle,
  footerStyle,
  loading,
  ...props
}) => {
  return (
    <Styled.Root
      style={{
        ...style,
        ...(fullHeight && {height: '100%'}),
      }}
      {...props}>
      <Styled.SectionHeader style={headerStyle}>
        <Styled.TitleContainer>
          {HeaderLeftComp}
          {title && <Styled.Title>{title}</Styled.Title>}
        </Styled.TitleContainer>
        {HeaderRightComp}
      </Styled.SectionHeader>
      <Styled.SectionBody>
        <Styled.BodyChildren
          fullHeight={fullHeight}
          style={{
            ...bodyStyle,
          }}>
          {children}
        </Styled.BodyChildren>
        {loading && <Styled.LoadingIndicator fill align="top" />}
      </Styled.SectionBody>
      {FooterComp && (
        <Styled.SectionFooter style={footerStyle}>
          {FooterComp}
        </Styled.SectionFooter>
      )}
    </Styled.Root>
  );
};

export default Section;
