import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import MUITableBody from '@material-ui/core/TableBody';
import MUITablePagination from '@material-ui/core/TablePagination';
import LoadingIndicator from '@macanta/components/LoadingIndicator';
import Button from '@macanta/components/Button';
import ColumnResizer from './ColumnResizer';
import {
  DEFAULT_COLUMN_MIN_WIDTH,
  DEFAULT_COLUMN_MAX_WIDTH,
} from '@macanta/themes/applicationStyles';
import isNil from 'lodash/isNil';
import * as Styled from './styles';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 440,
  },
  containerFullHeight: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  selectedRow: {
    backgroundColor: `${theme.palette.primary.light}!important`,
    '& > td': {
      color: 'white',
    },
  },
}));

const ResizableTableCell = ({
  column,
  order,
  orderBy,
  onSort,
  style,
  ...props
}) => {
  const [cellWidth, setCellWidth] = React.useState(0);
  const [hasResized, setHasResized] = React.useState(false);

  const cellRef = React.useRef(null);
  const currentWidthRef = React.useRef(null);

  const colMinWidth = column.minWidth || DEFAULT_COLUMN_MIN_WIDTH;
  const colMaxWidth = column.maxWidth || DEFAULT_COLUMN_MAX_WIDTH;
  const sortActive = orderBy === column.id;
  const direction = sortActive ? order : 'asc';

  const getCurrentWidth = () => {
    let currentWidth;
    if (currentWidthRef.current) {
      currentWidth = currentWidthRef.current;
    } else {
      const {width} = cellRef.current?.getBoundingClientRect();
      currentWidth = width;
    }

    return Math.min(Math.max(colMinWidth, currentWidth), colMaxWidth);
  };

  const getNewWidth = (deltaX, currentWidth) => {
    const percentageDeltaX = deltaX / currentWidth;

    const newWidth = currentWidth + currentWidth * percentageDeltaX;

    return Math.min(Math.max(colMinWidth, newWidth), colMaxWidth);
  };

  const handleStartSlide = () => {
    const currentWidth = getCurrentWidth();

    currentWidthRef.current = currentWidth;
  };

  const handleSliding = (deltaX) => {
    const currentWidth = getCurrentWidth();

    const newWidth = getNewWidth(deltaX, currentWidth);
    console.log('currentWidth', currentWidth, 'newWidth', newWidth);

    setCellWidth(newWidth);
    setHasResized(true);
  };

  const handleEndSlide = () => {
    currentWidthRef.current = null;
  };

  console.log('column.id', column.id, colMinWidth, column);

  return (
    <Styled.TableHeadCell
      ref={cellRef}
      key={column.id}
      align={column.align}
      style={{
        minWidth: colMinWidth,
        maxWidth: colMaxWidth,
        ...(!isNil(column.width) && {width: column.width}),
        ...style,
        ...(hasResized && {width: cellWidth}),
      }}
      sortDirection={sortActive ? order : false}
      {...props}>
      <Styled.TableSortLabel
        active={sortActive}
        direction={direction}
        onClick={onSort}
        IconComponent={(iconProps) => {
          return <Styled.SortIcon active={sortActive} {...iconProps} />;
        }}>
        <Styled.TableHeadCellText>{column.label}</Styled.TableHeadCellText>
      </Styled.TableSortLabel>
      <ColumnResizer
        onStartSlide={handleStartSlide}
        onSliding={handleSliding}
        onEndSlide={handleEndSlide}
      />
    </Styled.TableHeadCell>
  );
};

const EnhancedTableHead = ({
  columns,
  order,
  orderBy,
  onRequestSort,
  hasActionColumn,
  actionColumn,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const getHeadCellZIndex = (totalColLength, index) => {
    return totalColLength + 1 - index;
  };
  const disableDragging = (e) => {
    console.log('disableDragging');
    e.preventDefault();
  };

  return (
    <Styled.TableHead onMouseDown={disableDragging}>
      <Styled.TableHeadRow>
        {columns.map((column, index) => (
          <ResizableTableCell
            key={column.id}
            column={column}
            order={order}
            orderBy={orderBy}
            onSort={createSortHandler(column.id)}
            style={{
              zIndex: getHeadCellZIndex(columns.length, index),
            }}
          />
        ))}
        {hasActionColumn && (
          <Styled.TableHeadCell align="center">
            {actionColumn.label}
          </Styled.TableHeadCell>
        )}
      </Styled.TableHeadRow>
    </Styled.TableHead>
  );
};

EnhancedTableHead.defaultProps = {
  actionColumn: {label: ''},
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  actionColumn: PropTypes.object,
};

const Table = ({
  columns,
  data: rows,
  selectable,
  highlight,
  onSelectItem,
  hidePagination,
  page: pageProps,
  rowsPerPage: rowsPerPageProps,
  fullHeight,
  containerStyle,
  emptyContainerStyle,
  ActionButtonsComp,
  actionColumn,
  loading,
  hideEmptyMessage,
  hideColumns,
  numOfTextLines,
  disableLineClamp,
  ...props
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(pageProps);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageProps);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const hasActionButtons = !!ActionButtonsComp && columns.length > 0;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSelectItem = (item) => () => {
    console.log('Table handleSelectItem', item);
    setSelectedItem(item);
    onSelectItem(item);
  };

  React.useEffect(() => {
    setPage(pageProps);
  }, [pageProps]);

  React.useEffect(() => {
    setRowsPerPage(rowsPerPageProps);
  }, [rowsPerPageProps]);

  return (
    <Styled.Root
      style={
        fullHeight && {
          height: '100%',
        }
      }
      {...props}>
      <Styled.RootContainer>
        <Styled.TableContainer
          className={
            !fullHeight ? classes.container : classes.containerFullHeight
          }
          style={containerStyle}>
          <Styled.Table stickyHeader aria-label="sticky table">
            {!hideColumns && (
              <EnhancedTableHead
                columns={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                hasActionColumn={hasActionButtons}
                actionColumn={actionColumn}
              />
            )}
            <MUITableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  const selected = item.id === selectedItem?.id;

                  return (
                    <Styled.TableBodyRow
                      index={index}
                      className={clsx(
                        selectable &&
                          highlight &&
                          selected &&
                          classes.selectedRow,
                      )}
                      hover={selectable || hasActionButtons}
                      tabIndex={-1}
                      key={item.id}
                      {...(selectable && {
                        onClick: handleSelectItem(item),
                        selectable,
                      })}>
                      {columns.map((column) => {
                        const value = item[column.id];
                        const subtext = item[column.subtext];
                        const colMinWidth =
                          column.minWidth || DEFAULT_COLUMN_MIN_WIDTH;
                        const colMaxWidth =
                          column.maxWidth || DEFAULT_COLUMN_MAX_WIDTH;

                        return (
                          <Styled.TableBodyCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: colMinWidth,
                              maxWidth: colMaxWidth,
                              ...(!isNil(column.width) && {
                                width: column.width,
                              }),
                            }}>
                            <Styled.TableBodyCellLabel
                              disableLineClamp={disableLineClamp}
                              lines={numOfTextLines}
                              style={column.textStyle}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : Array.isArray(value)
                                ? value.map((v) => {
                                    const label = v.onClick ? (
                                      <Button
                                        onClick={v.onClick}
                                        style={{
                                          color: '#2196f3',
                                          padding: 0,
                                        }}>
                                        {v.label}
                                      </Button>
                                    ) : (
                                      v.label
                                    );

                                    return (
                                      <>
                                        {label}
                                        {!!v.subtext && (
                                          <Styled.Subtext>
                                            {v.subtext}
                                          </Styled.Subtext>
                                        )}
                                      </>
                                    );
                                  })
                                : value}
                            </Styled.TableBodyCellLabel>
                            {!!subtext && (
                              <Styled.Subtext>{subtext}</Styled.Subtext>
                            )}
                          </Styled.TableBodyCell>
                        );
                      })}
                      {hasActionButtons && (
                        <Styled.TableActionBodyCell
                          align="center"
                          className="actionButtonCol">
                          <ActionButtonsComp item={item} columns={columns} />
                        </Styled.TableActionBodyCell>
                      )}
                    </Styled.TableBodyRow>
                  );
                })}
            </MUITableBody>
          </Styled.Table>
          {!hideEmptyMessage && rows.length === 0 && (
            <Styled.EmptyMessageContainer style={emptyContainerStyle}>
              <Styled.EmptyMessage align="center" color="#888">
                No data found
              </Styled.EmptyMessage>
            </Styled.EmptyMessageContainer>
          )}
          {loading && <LoadingIndicator fill align="top" />}
        </Styled.TableContainer>
      </Styled.RootContainer>
      {!hidePagination && rows.length > 0 && (
        <Styled.TableFooter>
          <MUITablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Styled.TableFooter>
      )}
    </Styled.Root>
  );
};

Table.defaultProps = {
  columns: [],
  data: [],
  selectable: false,
  onSelectItem: () => {},
  page: 0,
  rowsPerPage: 10,
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
      minWidth: PropTypes.number,
      maxWidth: PropTypes.number,
      format: PropTypes.func,
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.object),
  selectable: PropTypes.bool,
  onSelectItem: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};

export default Table;
