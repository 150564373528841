import {experimentalStyled as styled} from '@material-ui/core/styles';

import MUIModal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';

export const Root = styled(MUIModal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({theme}) => `${theme.spacing(2)}`};

  ${({theme}) => theme.breakpoints.up('sm')} {
    padding: ${({theme}) => `${theme.spacing(2)} ${theme.spacing(8)}`};
  }
`;

export const Container = styled(Box)`
  display: flex;
  justify-content: center;
  max-height: 100%;
  pointer-events: none;
`;

export const InnerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.palette.common.white};
  border-radius: 4px;
  min-width: 320px;
  pointer-events: auto;
  max-height: 100%;
  overflow: scroll;
  position: relative;

  ${({theme}) => theme.breakpoints.up('sm')} {
    min-width: 500px;
  }
`;

export const ModalHeader = styled(Box)`
  padding: ${({theme}) => `${theme.spacing(0.5)}`}
    ${({theme}) => `${theme.spacing(2)}`};
  background-color: ${({theme}) => `${theme.palette.primary.main}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({theme}) => theme.palette.common.white};
`;

export const ModalBody = styled(Box)`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
