import {experimentalStyled as styled} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';

export const Container = styled(Box)`
  ${({transparent}) =>
    !transparent &&
    `
    width: 50px;
    height: 50px;
    padding: 12px;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.9);
    pointer-events: none;
    border-radius: 100%;
    box-shadow: 0px 0px 12px -6px #ccc;
  `}
`;
