import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import isString from 'lodash/isString';

const TextField = ({onEnterPress, onKeyDown, error, ...props}) => {
  const handleKeyPress = (e) => {
    if (e.keyCode == 13) {
      if (!props.multiline) {
        e.target.blur();
      }

      onEnterPress && onEnterPress(e);
    } else {
      onKeyDown && onKeyDown(e);
    }
  };
  return (
    <Styled.TextField
      onKeyDown={handleKeyPress}
      {...(!!error &&
        isString(error) && {
          helperText: error,
        })}
      error={!!error}
      {...props}
    />
  );
};

TextField.defaultProps = {
  onEnterPress: null,
  onKeyDown: null,
};

TextField.propTypes = {
  onEnterPress: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export default TextField;
