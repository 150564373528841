import React from 'react';
import Image from '@macanta/components/Image';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {GET_APP_SETTINGS} from '@macanta/graphql/admin';
import {useQuery} from '@apollo/client';

const useStyles = makeStyles(() => ({
  container: {
    width: '11.25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '100%',
    overflow: 'hidden',
  },
  image: {
    maxHeight: '100%',
  },
}));

const CompanyLogo = ({className, ...props}) => {
  const classes = useStyles();
  const appSettingsQuery = useQuery(GET_APP_SETTINGS, {
    fetchPolicy: 'cache-only',
  });

  const customLogo = appSettingsQuery.data?.getAppSettings.customLogo.imageData;

  if (!customLogo) {
    return null;
  }

  const base64Img = `data:image/png;base64,${customLogo}`;

  console.log('Logo appSettings', appSettingsQuery.data.getAppSettings);

  return (
    <div className={clsx(classes.container, className)} {...props}>
      <Image className={classes.image} src={base64Img} />
    </div>
  );
};

export default CompanyLogo;
