import React, {useRef, useState} from 'react';

import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {FETCH_POLICIES} from '@macanta/constants/fetchPolicies';
import Head from '@macanta/components/Head';
import LoadingIndicator from '@macanta/components/LoadingIndicator';
import {useLazyQuery} from '@apollo/client';
import {LOGIN} from '@macanta/graphql/auth';
import {experimentalStyled as styled} from '@material-ui/core/styles';
import Grid from '@macanta/components/Grid';
import Box from '@material-ui/core/Box';
import Button from '@macanta/components/Button';
import MUITextField from '@macanta/components/TextField';
import Paper from '@macanta/components/Paper';
import Form from '@macanta/components/Form';
import CompanyLogo from '@macanta/modules/CompanyLogo';
import loginValidationSchema from '@macanta/validations/login';
import * as Storage from '@macanta/utils/storage';
import Page from '@macanta/components/Page';
import {navigate} from 'gatsby';

export const Root = styled(Page)`
  background-color: white;
`;

export const Content = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
`;

export const FormPaper = styled(Paper)`
  margin-top: ${({theme}) => theme.spacing(4)};
  width: 90%;
  ${({theme}) => theme.breakpoints.up('sm')} {
    width: 68%;
    max-width: 31.25rem;
  }
`;

export const TextField = styled(MUITextField)`
  margin-bottom: ${({theme}) => theme.spacing(2)};
`;

export const Description = styled(Typography)`
  margin-bottom: ${({theme}) => theme.spacing(2)};
`;

export const GridItem = styled(Grid)`
  margin: ${({theme}) => theme.spacing(1)} 0;
`;

export const ButtonLeft = styled(Button)`
  width: 100%;
  ${({theme}) => theme.breakpoints.up('sm')} {
    width: auto;
  }
`;

export const ButtonRight = styled(Button)`
  float: right;
  width: 100%;
  ${({theme}) => theme.breakpoints.up('sm')} {
    width: auto;
  }
`;

const LoginPage = () => {
  const [loading, setLoading] = useState(false);

  const userRef = useRef({});

  const [callLoginQuery, loginQuery] = useLazyQuery(LOGIN, {
    fetchPolicy: FETCH_POLICIES.NETWORK_ONLY,
    onCompleted(data = {}) {
      if (data.login) {
        const {email} = userRef.current;
        const {sessionId, apiKey, firstName, lastName} = data.login;

        Storage.setItem('session', {sessionId, apiKey});
        Storage.setItem('userDetails', {email, firstName, lastName});

        setLoading(false);

        navigate('/app/dashboard', {replace: true});
      }
    },
    onError() {
      setLoading(false);
    },
  });

  const handleFormSubmit = (values) => {
    console.log('email & password', values.email, values.password);
    setLoading(true);

    userRef.current = values;

    callLoginQuery({
      variables: {
        email: values.email,
        password: values.password,
      },
    });
  };

  return (
    <Root>
      <Head title="Macanta" />
      <Content>
        {/* //TODO: Replace with appSettings macanta_custom_logo */}
        <CompanyLogo />
        <FormPaper elevation={3}>
          <Typography color="primary" variant="h6" align="center">
            Login
          </Typography>
          <Description variant="body2" align="center">
            Please fill in the form below
          </Description>

          <Form
            initialValues={{email: '', password: ''}}
            validationSchema={loginValidationSchema}
            onSubmit={handleFormSubmit}>
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              /* and other goodies */
            }) => (
              <>
                <TextField
                  error={errors.email}
                  autoComplete="email"
                  onChange={handleChange('email')}
                  label="Email"
                  variant="filled"
                  value={values.email}
                  fullWidth
                  onEnterPress={handleSubmit}
                />
                <TextField
                  error={errors.password}
                  autoComplete="current-password"
                  password
                  onChange={handleChange('password')}
                  label="Password"
                  variant="filled"
                  value={values.password}
                  fullWidth
                  onEnterPress={handleSubmit}
                />

                {!!loginQuery.error && (
                  <Typography color="error" variant="subtitle2" align="center">
                    {loginQuery.error.message}
                  </Typography>
                )}

                <GridItem container>
                  <GridItem item xs={12} sm={8}>
                    <ButtonLeft startIcon={<InfoIcon />}>
                      Forgot password?
                    </ButtonLeft>
                  </GridItem>
                  <GridItem item xs={12} sm={4}>
                    <ButtonRight
                      onClick={handleSubmit}
                      color="primary"
                      variant="contained"
                      startIcon={<ExitToAppIcon />}>
                      Log me in...
                    </ButtonRight>
                  </GridItem>
                </GridItem>
              </>
            )}
          </Form>
        </FormPaper>
      </Content>
      <LoadingIndicator modal loading={loading} />
    </Root>
  );
};

export default LoginPage;
