import React, { useRef} from 'react';
import * as Styled from './styles';

const ColumnResizer = ({onStartSlide, onSliding, onEndSlide}) => {
  const prevClientXRef = useRef();

  const handleMouseDown = (mdEvent) => {
    onStartSlide();

    const startX = mdEvent.clientX;
    console.log('handleMouseDown startX:', startX);

    const handleMouseMove = (event) => {
      const clientX = event.clientX;
      if (clientX !== prevClientXRef.current) {
        prevClientXRef.current = clientX;

        const deltaX = clientX - startX;

        console.log(
          'handleMouseMove',
          `startX: ${startX}`,
          `clientX: ${clientX}`,
          `deltaX: ${deltaX}`,
        );

        onSliding(deltaX);
      }
    };

    const handleMouseUp = () => {
      onEndSlide();

      console.log(
        'handleMouseUp remove events',
        typeof handleMouseMove,
        typeof handleMouseUp,
      );

      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener(
      'mousemove',
      handleMouseMove,
      passiveEventSupported,
    );
    document.addEventListener('mouseup', handleMouseUp, passiveEventSupported);
  };

  return (
    <Styled.ColumnResizerContainer onMouseDown={handleMouseDown}>
      <Styled.ColumnResizerChild />
    </Styled.ColumnResizerContainer>
  );
};

let passiveSupported = null;
function passiveEventSupported() {
  // memoize support to avoid adding multiple test events
  if (typeof passiveSupported === 'boolean') return passiveSupported;

  let supported = false;
  try {
    const options = {
      get passive() {
        supported = true;
        return false;
      },
    };

    window.addEventListener('test', null, options);
    window.removeEventListener('test', null, options);
  } catch (err) {
    supported = false;
  }
  passiveSupported = supported;
  return passiveSupported;
}

export default ColumnResizer;
