import {experimentalStyled as styled} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Box';
//TODO: use core IconButton for now
//cannot use @macanta/components/Button/IconButton because of weird Tooltip bug not showing on hover
import IconButton from '@material-ui/core/IconButton';
import Search from '@macanta/containers/Search';

export const Root = styled(Search)``;

export const DOTypeButtonGroupContainer = styled(Box)`
  padding: ${({theme}) => theme.spacing(2)};
`;

export const ActionButtons = styled(Box)`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }

  ${({openOtherDetails}) => openOtherDetails && 'opacity: 1!important;'}
`;

export const ActionButtonContainer = styled(Box)``;

export const ActionButton = styled(IconButton)`
  padding: 0;
  color: ${({theme}) => theme.palette.info.main};
`;

export const OtherDetails = styled(Typography)`
  font-size: 14px;
  line-height: 24px;
`;

export const OtherDetailsKey = styled(Typography)`
  font-weight: bold;
`;

export const PopoverHeader = styled(Box)`
  padding: ${({theme}) => `${theme.spacing(0.5)}`}
    ${({theme}) => `${theme.spacing(2)}`};
  background-color: ${({theme}) => `${theme.palette.primary.main}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({theme}) => theme.palette.common.white};
`;
