import React from 'react';
import Section from './Section';
import {useTheme} from '@material-ui/core/styles';

const SubSection = ({title, ...props}) => {
  const theme = useTheme();

  return (
    <Section
      headerStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '2.55rem',
        ...(!title && {display: 'none'}),
      }}
      bodyStyle={{
        padding: theme.spacing(2),
      }}
      title={title}
      {...props}
    />
  );
};

export default SubSection;
