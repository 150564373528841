import {gql} from '@apollo/client';

export default gql`
  mutation connectOrDisconnectRelationship(
    $connectOrDisconnectRelationshipInput: ConnectOrDisconnectRelationshipInput!
  ) {
    connectOrDisconnectRelationship(
      input: $connectOrDisconnectRelationshipInput
    ) {
      success
    }
  }
`;
