import React, {useEffect, useState} from 'react';
import contactValidationSchema from '@macanta/validations/contact';
import Form from '@macanta/components/Form';
import Grid from '@macanta/components/Grid';
import Typography from '@material-ui/core/Typography';
import {useMutation} from '@apollo/client';
import {
  CREATE_OR_UPDATE_CONTACT,
  LIST_CONTACTS,
} from '@macanta/graphql/contacts';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@macanta/components/Collapse';
import Button from '@macanta/components/Button';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import range from 'lodash/range';
import AddIcon from '@material-ui/icons/Add';
import LoadingIndicator from '@macanta/components/LoadingIndicator';
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT,
} from '@macanta/modules/hoc/ContactsQuery';
import * as Styled from './styles';

const ContactForms = (props) => {
  const {onSuccess, contact = {}} = props;
  const [phoneAmt, setPhoneAmt] = useState(
    !contact?.phoneNumbers?.length
      ? [0]
      : range(0, contact.phoneNumbers.length),
  );
  const [expandedBillingAddress, setExpandedBillingAddress] = useState(false);
  const [expandedShippingAddress, setExpandedShippingAddress] = useState(false);

  const [callContactMutation, contactMutation] = useMutation(
    CREATE_OR_UPDATE_CONTACT,
    {
      update(cache, {data: {createOrUpdateContact}}) {
        console.log('CACHE', cache);
        console.log('CREATE_OR_UPDATE_CONTACT', createOrUpdateContact);
        cache.modify({
          fields: {
            listContacts(listContactsRef) {
              cache.modify({
                id: listContactsRef.__ref,
                fields: {
                  items(existingItems = []) {
                    console.log('Contacts existingItems', existingItems);
                    const newItemRef = cache.writeQuery({
                      data: createOrUpdateContact,
                      query: LIST_CONTACTS,
                    });
                    console.log('newItemRef', newItemRef);
                    return [newItemRef, ...existingItems];
                  },
                },
              });
            },
          },
        });

        cache.writeQuery({
          query: LIST_CONTACTS,
          data: {
            listContacts: {
              __typename: 'ContactItems',
              items: [createOrUpdateContact],
              count: 1,
            },
          },
          variables: {
            q: createOrUpdateContact.id,
            page: DEFAULT_PAGE,
            limit: DEFAULT_PAGE_LIMIT,
          },
        });
      },
      onError() {},
    },
  );

  console.log('contactMutation', contactMutation);

  const handleFormSubmit = (values) => {
    console.log('contact mutation', values);
    const newContact = {
      email: values.email,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      birthday: values.birthday,
      title: values.title,
      company: values.company,
      emailAddress2: values.emailAddress2,
      emailAddress3: values.emailAddress3,
      website: values.website,
      jobTitle: values.jobTitle,
      phoneNumbers: values.phoneNumbers,
      streetAddress1: values.streetAddress1,
      streetAddress2: values.streetAddress2,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      country: values.country,
      address2Street1: values.address2Street1,
      address2Street2: values.address2Street2,
      city2: values.city2,
      state2: values.state2,
      postalCode2: values.postalCode2,
      country2: values.country2,
    };

    callContactMutation({
      variables: {
        createOrUpdateContactInput: newContact,
        __mutationkey: 'createOrUpdateContactInput',
      },
    });
  };

  const handleAddPhone = () => {
    setPhoneAmt((prevState) =>
      prevState.concat(prevState[prevState.length - 1] + 1),
    );
  };

  const handleExpandedBillingAddress = () => {
    setExpandedBillingAddress(!expandedBillingAddress);
  };

  const handleExpandedShippingAddress = () => {
    setExpandedShippingAddress(!expandedShippingAddress);
  };

  useEffect(() => {
    if (contactMutation.data?.createOrUpdateContact) {
      onSuccess(contactMutation.data?.createOrUpdateContact);
    }
  }, [contactMutation.data]);

  return (
    <Styled.Root {...props}>
      <Styled.FormContainer>
        <Form
          initialValues={contact}
          validationSchema={contactValidationSchema}
          onSubmit={handleFormSubmit}>
          {({values, errors, handleChange, handleSubmit}) => (
            <>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Styled.FormGroup>
                    <Styled.TextField
                      error={errors.firstName}
                      onChange={handleChange('firstName')}
                      label="First Name"
                      variant="outlined"
                      value={values.firstName}
                      fullWidth
                      size="medium"
                      onEnterPress={handleSubmit}
                    />

                    <Styled.TextField
                      error={errors.middleName}
                      onChange={handleChange('middleName')}
                      label="Middle Name"
                      variant="outlined"
                      value={values.middleName}
                      fullWidth
                      size="medium"
                      onEnterPress={handleSubmit}
                    />

                    <Styled.TextField
                      error={errors.lastName}
                      onChange={handleChange('lastName')}
                      label="Last Name"
                      variant="outlined"
                      value={values.lastName}
                      fullWidth
                      size="medium"
                      onEnterPress={handleSubmit}
                    />

                    <Styled.TextField
                      date
                      DatePickerProps={{
                        inputFormat: 'yyyy-MM-dd',
                        outputFormat: 'YYYY-MM-DD',
                      }}
                      error={errors.birthday}
                      onChange={handleChange('birthday')}
                      label="DOB / Birthday"
                      variant="outlined"
                      value={values.birthday}
                      fullWidth
                      size="medium"
                      onEnterPress={handleSubmit}
                    />

                    <Styled.TextField
                      error={errors.email}
                      autoComplete="email"
                      onChange={handleChange('email')}
                      label="Email"
                      variant="outlined"
                      value={values.email}
                      fullWidth
                      size="medium"
                      onEnterPress={handleSubmit}
                    />

                    <Styled.TextField
                      error={errors.title}
                      autoComplete="honorific-prefix"
                      onChange={handleChange('title')}
                      label="Title"
                      variant="outlined"
                      value={values.title}
                      fullWidth
                      size="medium"
                      onEnterPress={handleSubmit}
                    />

                    <Styled.TextField
                      error={errors.jobTitle}
                      autoComplete="organization-title"
                      onChange={handleChange('jobTitle')}
                      label="JobTitle"
                      variant="outlined"
                      value={values.jobTitle}
                      fullWidth
                      size="medium"
                      onEnterPress={handleSubmit}
                    />

                    <Styled.TextField
                      error={errors.company}
                      autoComplete="organization"
                      onChange={handleChange('company')}
                      label="Company"
                      variant="outlined"
                      value={values.company}
                      fullWidth
                      size="medium"
                      onEnterPress={handleSubmit}
                    />
                  </Styled.FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Styled.FormGroup>
                    {phoneAmt.map((amt, phoneIndex) => {
                      return (
                        <Styled.TextField
                          key={phoneIndex}
                          error={errors[`phoneNumbers[${phoneIndex}]`]}
                          autoComplete="tel"
                          onChange={handleChange(`phoneNumbers[${phoneIndex}]`)}
                          label={`Phone ${phoneIndex + 1}`}
                          variant="outlined"
                          value={
                            values.phoneNumbers &&
                            values.phoneNumbers[phoneIndex]
                          }
                          fullWidth
                          size="medium"
                          onEnterPress={handleSubmit}
                        />
                      );
                    })}
                    <Button onClick={handleAddPhone} startIcon={<AddIcon />}>
                      Add Phone
                    </Button>
                  </Styled.FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Styled.FormGroup>
                    <Collapse
                      in={expandedBillingAddress}
                      timeout="auto"
                      unmountOnExit>
                      <Styled.TextField
                        error={errors.streetAddress1}
                        autoComplete="street-address"
                        onChange={handleChange('streetAddress1')}
                        label="Address 1"
                        variant="outlined"
                        value={values.streetAddress1}
                        fullWidth
                        size="medium"
                        onEnterPress={handleSubmit}
                      />
                      <Styled.TextField
                        error={errors.streetAddress2}
                        onChange={handleChange('streetAddress2')}
                        label="Address 2"
                        variant="outlined"
                        value={values.streetAddress2}
                        fullWidth
                        size="medium"
                        onEnterPress={handleSubmit}
                      />
                      <Styled.TextField
                        error={errors.city}
                        autoComplete="address-level2"
                        onChange={handleChange('city')}
                        label="City"
                        variant="outlined"
                        value={values.city}
                        fullWidth
                        size="medium"
                        onEnterPress={handleSubmit}
                      />
                      <Styled.TextField
                        error={errors.state}
                        autoComplete="address-level1"
                        onChange={handleChange('state')}
                        label="State"
                        variant="outlined"
                        value={values.state}
                        fullWidth
                        size="medium"
                        onEnterPress={handleSubmit}
                      />
                      <Styled.TextField
                        error={errors.postalCode}
                        autoComplete="postal-code"
                        onChange={handleChange('postalCode')}
                        label="Postal Code"
                        variant="outlined"
                        value={values.postalCode}
                        fullWidth
                        size="medium"
                        onEnterPress={handleSubmit}
                      />
                      <Styled.TextField
                        error={errors.country}
                        autoComplete="country-name"
                        onChange={handleChange('country')}
                        label="Country"
                        variant="outlined"
                        value={values.country}
                        fullWidth
                        size="medium"
                        onEnterPress={handleSubmit}
                      />
                    </Collapse>
                    <Button
                      onClick={handleExpandedBillingAddress}
                      variant="contained"
                      startIcon={<ContactMailIcon />}>
                      {!expandedBillingAddress ? 'Add' : 'Remove'} Billing
                      Address
                    </Button>
                  </Styled.FormGroup>

                  <Styled.FormGroup>
                    <Collapse
                      in={expandedShippingAddress}
                      timeout="auto"
                      unmountOnExit>
                      <Styled.TextField
                        error={errors.address2Street1}
                        autoComplete="street-address"
                        onChange={handleChange('address2Street1')}
                        label="Address 1"
                        variant="outlined"
                        value={values.address2Street1}
                        fullWidth
                        size="medium"
                        onEnterPress={handleSubmit}
                      />
                      <Styled.TextField
                        error={errors.address2Street2}
                        onChange={handleChange('address2Street2')}
                        label="Address 2"
                        variant="outlined"
                        value={values.address2Street2}
                        fullWidth
                        size="medium"
                        onEnterPress={handleSubmit}
                      />
                      <Styled.TextField
                        error={errors.city2}
                        autoComplete="address-level2"
                        onChange={handleChange('city2')}
                        label="City"
                        variant="outlined"
                        value={values.city2}
                        fullWidth
                        size="medium"
                        onEnterPress={handleSubmit}
                      />
                      <Styled.TextField
                        error={errors.state2}
                        autoComplete="address-level1"
                        onChange={handleChange('state2')}
                        label="State"
                        variant="outlined"
                        value={values.state2}
                        fullWidth
                        size="medium"
                        onEnterPress={handleSubmit}
                      />
                      <Styled.TextField
                        error={errors.postalCode2}
                        autoComplete="postal-code"
                        onChange={handleChange('postalCode2')}
                        label="Postal Code"
                        variant="outlined"
                        value={values.postalCode2}
                        fullWidth
                        size="medium"
                        onEnterPress={handleSubmit}
                      />
                      <Styled.TextField
                        error={errors.country2}
                        autoComplete="country-name"
                        onChange={handleChange('country2')}
                        label="Country"
                        variant="outlined"
                        value={values.country2}
                        fullWidth
                        size="medium"
                        onEnterPress={handleSubmit}
                      />
                    </Collapse>
                    <Button
                      onClick={handleExpandedShippingAddress}
                      variant="contained"
                      startIcon={<LocalShippingIcon />}>
                      {!expandedShippingAddress ? 'Add' : 'Remove'} Shipping
                      Address
                    </Button>
                  </Styled.FormGroup>
                </Grid>
              </Grid>
              {!!contactMutation.error && (
                <Typography color="error" variant="subtitle2" align="center">
                  {contactMutation.error.message}
                </Typography>
              )}

              <Styled.Footer>
                <Styled.FooterButton startIcon={<CloseIcon />}>
                  Cancel
                </Styled.FooterButton>
                <Styled.FooterButton
                  variant="contained"
                  startIcon={<TurnedInIcon />}
                  onClick={handleSubmit}
                  size="medium">
                  Save
                </Styled.FooterButton>
              </Styled.Footer>
            </>
          )}
        </Form>
      </Styled.FormContainer>
      <LoadingIndicator modal loading={contactMutation.loading} />
    </Styled.Root>
  );
};

ContactForms.propTypes = {};

export default ContactForms;
