import {gql} from '@apollo/client';

export default gql`
  query listDataObjects(
    $appName: String!
    $apiKey: String!
    $q: String
    $contactId: ID
  ) {
    listDataObjects(
      appName: $appName
      apiKey: $apiKey
      q: $q
      contactId: $contactId
    ) {
      items {
        type
        items {
          itemId
          data {
            name
            value
          }
          connectedContacts {
            contactId
            email
            firstName
            lastName
            relationships {
              role
            }
          }
        }
      }
    }
  }
`;
