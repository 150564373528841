import {gql} from '@apollo/client';
import {CONTACT_ATTRIBUTES} from './fragments';

export default gql`
  query listContacts(
    $appName: String!
    $apiKey: String!
    $q: String
    $page: Int
    $limit: Int
  ) {
    listContacts(
      appName: $appName
      apiKey: $apiKey
      q: $q
      page: $page
      limit: $limit
    ) {
      items {
        ...contactResults
      }
      count
    }
  }
  ${CONTACT_ATTRIBUTES}
`;
