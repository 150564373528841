import React from 'react';
import LoadingIndicator from './LoadingIndicator';
import LoadingIndicatorModal from './LoadingIndicatorModal';

const IndexLoadingIndicator = ({modal, ModalProps, ...props}) => {
  if (modal) {
    return <LoadingIndicatorModal ModalProps={ModalProps} {...props} />;
  }

  return <LoadingIndicator {...props} />;
};

export default IndexLoadingIndicator;

export {LoadingIndicator, LoadingIndicatorModal};
