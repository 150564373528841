import React, {useState, useEffect, useRef} from 'react';
import Section from '@macanta/containers/Section';
import List from '@material-ui/core/List';
import {useQuery} from '@apollo/client';
import {LIST_DATA_OBJECT_TYPES} from '@macanta/graphql/dataObjects';

import {useNavigate} from '@reach/router';
import * as Styled from './styles';

export const DEFAULT_SELECTED_TAB = 'notes';

const SidebarAccess = ({contactId, selectedTab = DEFAULT_SELECTED_TAB}) => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(selectedTab);
  const hasLoadedRef = useRef(false);

  const doTypesQuery = useQuery(LIST_DATA_OBJECT_TYPES, {});
  const listDOTypes = doTypesQuery.data?.listDataObjectTypes;

  const handleSelectTab = (contactId, type, doType) => () => {
    if (type === 'notes') {
      setSelectedItem('notes');
      navigate(`/app/dashboard/${contactId}/notes`);
    } else if (type === 'data-object') {
      setSelectedItem(doType.id);
      navigate(
        `/app/dashboard/${contactId}/data-object/${doType.id}/${doType.title}`,
      );
    }
  };

  useEffect(() => {
    if (hasLoadedRef.current) {
      setSelectedItem('notes');
    } else {
      hasLoadedRef.current = true;
    }
  }, [contactId]);

  useEffect(() => {
    setSelectedItem(selectedTab);
  }, [selectedTab]);

  console.log(
    'SidebarAccess',
    contactId,
    selectedItem,
    selectedTab,
    hasLoadedRef.current,
  );

  return (
    <Section fullHeight loading={!listDOTypes} title="Access">
      {listDOTypes && (
        <>
          <List
            style={{
              paddingTop: '0.5rem',
            }}>
            <Styled.ListItem
              selected={selectedItem === 'notes'}
              button
              onClick={handleSelectTab(contactId, 'notes')}>
              <Styled.ListItemText primary={'Notes'} />
            </Styled.ListItem>
            {/* <ListSubheader
              style={{
                paddingLeft: '0.875rem',
                paddingRight: '0.875rem',
                height: '2.3rem',
                lineHeight: '2.75rem',
              }}>
              Data Objects
            </ListSubheader> */}
            {listDOTypes.map((doType) => {
              return (
                <Styled.ListItem
                  key={doType.id}
                  selected={selectedItem === doType.id}
                  button
                  onClick={handleSelectTab(contactId, 'data-object', doType)}>
                  <Styled.ListItemText primary={doType.title} />
                </Styled.ListItem>
              );
            })}
          </List>
        </>
      )}
    </Section>
  );
};

SidebarAccess.propTypes = {};

export default SidebarAccess;
