import {experimentalStyled as styled} from '@material-ui/core/styles';

import MUIListItem from '@material-ui/core/ListItem';
import MUIListItemText from '@material-ui/core/ListItemText';

export const ListItem = styled(MUIListItem)`
  padding: 4px 1rem;
`;

export const ListItemText = styled(MUIListItemText)``;
