import {useQuery} from '@apollo/client';
import {LIST_DATA_OBJECT_FIELDS} from '@macanta/graphql/dataObjects';

const useDOFields = (groupId, options = {}) => {
  const {loading, data, error} = useQuery(LIST_DATA_OBJECT_FIELDS, {
    variables: {
      groupId,
    },
    ...options,
  });

  return {loading, data, error};
};

export default useDOFields;
