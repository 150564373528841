import React, {useState, useCallback} from 'react';
import debounce from 'lodash/debounce';
import ContactsSearchTable from './ContactsSearchTable';
import DataObjectsSearchTable from './DataObjectsSearchTable';
import isNil from 'lodash/isNil';
import uniq from 'lodash/uniq';
import Modal from '@macanta/components/Modal';
import * as Storage from '@macanta/utils/storage';
import * as Styled from './styles';

const DEFAULT_CATEGORY = 'contact';

const DEFAULT_CATEGORIES = [
  {
    value: 'contact',
    label: 'Contact',
  },
  {
    value: 'dataObject',
    label: 'Data Object',
  },
];

const getOptionLabel = (option) => {
  return !isNil(option?.firstName)
    ? `${option.firstName} ${option.lastName}`.trim()
    : option;
};

const getSuggestionsStringKey = (category) => {
  let suggestionsStringKey = '';

  switch (category) {
    case 'contact': {
      suggestionsStringKey = 'contact_search_suggestions';
      break;
    }
    case 'dataObject': {
      suggestionsStringKey = 'data_object_search_suggestions';
      break;
    }
  }

  return suggestionsStringKey;
};

const getCachedSuggestions = (category = DEFAULT_CATEGORY) => {
  const suggestionsStringKey = getSuggestionsStringKey(category);

  if (!suggestionsStringKey) {
    return [];
  }

  const cachedSuggestions = Storage.getItem(suggestionsStringKey);

  return cachedSuggestions?.suggestions || [];
};

const SearchTable = ({category, ...props}) => {
  let searchTable;

  switch (category) {
    case 'contact': {
      searchTable = <ContactsSearchTable {...props} />;
      break;
    }
    case 'dataObject': {
      searchTable = <DataObjectsSearchTable {...props} />;
      break;
    }
  }

  return searchTable;
};

const UniversalSearch = ({
  categories = DEFAULT_CATEGORIES,
  defaultCategory = DEFAULT_CATEGORY,
  onSelectItem,
  modal,
  columns,
  TableProps,
  defaultSearchQuery,
  autoSearch,
  autoSelect,
  ...props
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(DEFAULT_CATEGORY);
  const [suggestions, setSuggestions] = useState(getCachedSuggestions());
  const [searchQuery, setSearchQuery] = useState(defaultSearchQuery);

  const handleSuggestionsSearch = useCallback(
    debounce((value) => {
      handleSearch({value});
    }, 600),
    [],
  );

  const handleChangeText = (value, category) => {
    if (autoSearch) {
      handleSuggestionsSearch(value, category);
    }
  };

  const handleCacheSuggestions = (value) => {
    if (value) {
      const cachedSuggestions = getCachedSuggestions(selectedCategory);
      const newSuggestions = uniq([value].concat(cachedSuggestions));
      const latest100suggestions = newSuggestions.slice(0, 100);

      Storage.setItem(getSuggestionsStringKey(selectedCategory), {
        suggestions: latest100suggestions,
      });

      setSuggestions(newSuggestions);
    }
  };

  const handleSearch = ({value, limit}) => {
    setShowModal(true);
    setSearchQuery({search: value, page: 0, limit});

    handleCacheSuggestions(value);
  };

  const handleTriggerSearch = (params) => {
    if (!autoSearch) {
      handleSearch(params);
    }
  };

  const handleSelectSuggestion = ({value}) => {
    handleSearch({value});
  };

  const handleSelectItem = (item) => {
    console.log('UniversalSearch handleSelectItem', item);
    setShowModal(false);
    onSelectItem(item);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChangeCategory = (category) => {
    const cachedSuggestions = getCachedSuggestions(category);

    setSelectedCategory(category);
    setSuggestions(cachedSuggestions);
  };

  console.log('searchQuery & suggestions', searchQuery, suggestions);

  let searchTable = !!searchQuery && (
    <SearchTable
      category={selectedCategory}
      searchQuery={searchQuery}
      columns={columns}
      autoSelect={autoSelect}
      onSelectItem={handleSelectItem}
      onCloseModal={handleCloseModal}
      {...TableProps}
    />
  );

  if (modal) {
    searchTable = (
      <Modal
        headerTitle={'Search Window'}
        open={showModal}
        onClose={handleCloseModal}
        contentWidth={'100%'}
        contentHeight={'90%'}>
        {searchTable}
      </Modal>
    );
  }

  return (
    <>
      <Styled.Root
        {...props}
        onSearch={handleTriggerSearch}
        onChange={handleChangeText}
        getOptionLabel={getOptionLabel}
        categories={categories}
        defaultCategory={defaultCategory}
        onChangeCategory={handleChangeCategory}
        suggestions={suggestions}
        onSelectSuggestion={handleSelectSuggestion}
      />
      {searchTable}
    </>
  );
};

UniversalSearch.defaultProps = {
  onSelectItem: () => {},
  autoSelect: false,
  modal: false,
};

export default UniversalSearch;
