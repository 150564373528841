import React from 'react';
import Header from '@macanta/containers/Header';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import {experimentalStyled as styled} from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ContactFormsIconBtn from '@macanta/modules/ContactForms/ContactFormsIconBtn';
import * as HeaderStyled from '@macanta/containers/Header/styles';

// export const Title = styled(Typography)`
//   display: none;
//   ${({theme}) => theme.breakpoints.up('sm')} {
//     display: block;
//   }
// `;

const DashboardHeader = ({children, onSaveContact, ...props}) => {
  const matches = useMediaQuery('(min-width:400px)');
  // const companyName = 'Macanta';

  const renderDesktopMenu = (
    <>
      <ContactFormsIconBtn onSaveContact={onSaveContact} />
      <HeaderStyled.NavIconButton>
        <Badge badgeContent={4} color="info">
          <MailIcon />
        </Badge>
      </HeaderStyled.NavIconButton>
      <HeaderStyled.NavIconButton>
        <Badge badgeContent={17} color="info">
          <NotificationsIcon />
        </Badge>
      </HeaderStyled.NavIconButton>
    </>
  );

  const renderMobileMenu = (
    <>
      <ContactFormsIconBtn mobile onSaveContact={onSaveContact} />

      <HeaderStyled.NavMobileMenuItem>
        <HeaderStyled.NavMobileIconButton>
          <Badge badgeContent={4} color="info">
            <MailIcon />
          </Badge>
        </HeaderStyled.NavMobileIconButton>
        <p>Messages</p>
      </HeaderStyled.NavMobileMenuItem>
      <HeaderStyled.NavMobileMenuItem>
        <HeaderStyled.NavMobileIconButton>
          <Badge badgeContent={11} color="info">
            <NotificationsIcon />
          </Badge>
        </HeaderStyled.NavMobileIconButton>
        <p>Notifications</p>
      </HeaderStyled.NavMobileMenuItem>
    </>
  );

  return (
    <>
      <Header
        renderDesktopMenu={renderDesktopMenu}
        renderMobileMenu={renderMobileMenu}
        {...props}>
        {/* {Boolean(companyName) && (
          <Title variant="h6" color="inherit" noWrap>
            {companyName}
          </Title>
        )} */}
        {matches && children}
      </Header>
      {!matches && children}
    </>
  );
};

export default DashboardHeader;
