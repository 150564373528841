const UNIT_SPACING = 8;

export const spacing = UNIT_SPACING;
export const DEFAULT_HEAD_ROW_HEIGHT = 58;
export const DEFAULT_COLUMN_MIN_WIDTH = 170;
export const DEFAULT_COLUMN_MAX_WIDTH = 300;

export const fullHeightFlexRow = `
  height: 100%;
  display: flex;
`;

export const fullHeightFlexColumn = `
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const flexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const rowCenterJustifyStart = `
  ${flexCenter}
  justify-content: flex-start;
`;

export const rowCenterJustifyBetween = `
  ${flexCenter}
  justify-content: space-between;
`;

export const oneLineText = `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const getLineText = (lines = 1) => {
  return `
    ${oneLineText}
    -webkit-line-clamp: ${lines};
  `;
};

export const footer = `
  padding: 0px ${({theme}) => theme.spacing(2)};
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0px -4px 10px -2px #eee;
  z-index: 10;
`;
