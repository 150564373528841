import {experimentalStyled as styled} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import SearchIconComp from '@material-ui/icons/Search';
import TextField from '@macanta/components/TextField';
import Button, {IconButton} from '@macanta/components/Button';

export const Root = styled(Box)`
  display: flex;
`;

export const SearchSelect = styled(TextField)`
  ${({theme}) => theme.breakpoints.up('sm')} {
    min-width: 120px;
  }
`;

export const SearchTextField = styled(TextField)`
  width: 100%;
`;

export const ActionButton = styled(Button)`
  border-radius: 0;
  box-shadow: none;
`;

export const SearchIconButton = styled(IconButton)`
  height: 40px;
  width: 40px;
`;

export const SearchIcon = styled(SearchIconComp)`
  color: #ccc;
`;
