import React from 'react';
import Button from './Button';
import IconButton from './IconButton';

const IndexButton = ({icon, IconButtonProps, ...props}) => {
  if (icon) {
    return <IconButton {...props} {...IconButtonProps} />;
  }

  return <Button {...props} />;
};

export default IndexButton;

export {Button, IconButton};
