import React, {useState} from 'react';
import TextField from './TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/core/Autocomplete';

export const DEFAULT_OPTIONS_LIMIT = 5;

const defaultFilterOptions = createFilterOptions({
  limit: DEFAULT_OPTIONS_LIMIT,
});

const SearchField = ({
  AutoCompleteProps,
  value,
  onChange,
  onSelectOption,
  clearOnSelect,
  ...props
}) => {
  const {
    filterOptions = defaultFilterOptions,
    style: autoCompleteStyle,
    ...autoCompleteProps
  } = AutoCompleteProps;

  const [selectedSuggestion, setSelectedSuggestion] = useState(value);
  const [inputValue, setInputValue] = useState(value);

  const handleSuggestionChange = (e, sValue, reason) => {
    console.log('Autocomplete handleSuggestionChange: ' + sValue, reason);
    if (clearOnSelect) {
      setInputValue('');
    }

    setSelectedSuggestion('');

    if (reason === 'select-option') {
      onSelectOption(sValue);
    }
  };

  const handleInputValue = (event, newInputValue, reason) => {
    console.log('handleInputValue:' + newInputValue, 'reason:', reason);
    setInputValue(newInputValue);

    onChange(newInputValue);
  };

  // useEffect(() => {
  //   setInputValue(value);
  // }, [value]);

  return (
    <Autocomplete
      autoHighlight
      disableClearable
      freeSolo
      onChange={handleSuggestionChange}
      value={selectedSuggestion}
      onInputChange={handleInputValue}
      inputValue={inputValue}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...props}
            style={{
              width: '100%',
              height: '100%',
            }}
            InputProps={{
              ...params.InputProps,
              ...props.InputProps,
              style: {
                ...props.InputProps?.style,
              },
            }}
            inputProps={{
              ...params.inputProps,
              ...props.inputProps,
              style: {
                ...props.inputProps?.style,
              },
            }}
          />
        );
      }}
      filterOptions={filterOptions}
      style={{
        width: '100%',
        height: '100%',
        ...autoCompleteStyle,
      }}
      {...autoCompleteProps}
    />
  );
};

export default SearchField;
