import React from 'react';

import Modal from '@material-ui/core/Modal';
import LoadingIndicator from './LoadingIndicator';

const LoadingIndicatorModal = ({ModalProps, loading, ...props}) => (
  <Modal open={loading} {...ModalProps}>
    <LoadingIndicator fill transparent color="white" {...props} />
  </Modal>
);

export default LoadingIndicatorModal;
