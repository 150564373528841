import React from 'react';
import TextField from './TextField';
import SelectField from './SelectField';
import PasswordField from './PasswordField';
import SearchField from './SearchField';
import DatePickerField from './DatePickerField';
import DateTimePickerField from './DateTimePickerField';

const IndexTextField = ({
  select,
  password,
  search,
  AutoCompleteProps,
  date,
  DatePickerProps,
  datetime,
  DateTimePickerProps,
  ...props
}) => {
  if (select) {
    return <SelectField {...props} />;
  } else if (password) {
    return <PasswordField {...props} />;
  } else if (search) {
    return <SearchField {...props} AutoCompleteProps={AutoCompleteProps} />;
  } else if (date) {
    return <DatePickerField {...props} DatePickerProps={DatePickerProps} />;
  } else if (datetime) {
    return (
      <DateTimePickerField
        {...props}
        DateTimePickerProps={DateTimePickerProps}
      />
    );
  }

  return <TextField {...props} />;
};

export default IndexTextField;

export {TextField, PasswordField};
