import React, {useState, useEffect} from 'react';
import MUIButtonGroup from '@material-ui/core/ButtonGroup';
import {getSelectedVariantAttrs} from './helpers';

const ButtonGroup = ({children, selectedButtonIndex, ...props}) => {
  const [selectedIndex, setSelectedIndex] = useState(selectedButtonIndex);

  const handleClick = (onClick, index) => () => {
    setSelectedIndex(index);
    onClick(index);
  };

  useEffect(() => {
    setSelectedIndex(selectedButtonIndex);
  }, [selectedButtonIndex]);

  console.log(
    'ButtonGroup variant',
    props.variant,
    getSelectedVariantAttrs(props.variant),
  );

  return (
    <MUIButtonGroup {...props}>
      {React.Children.map(children, (child, index) => {
        const selected = selectedIndex === index;
        const selectedVariantAttrs = selected
          ? getSelectedVariantAttrs(props.variant, child.props.style)
          : {};
        console.log(
          'selectedVariantAttrs',
          selectedVariantAttrs.variant,
          selectedVariantAttrs.style,
        );
        return React.cloneElement(child, {
          onClick: handleClick(child.props.onClick, index),
          ...selectedVariantAttrs,
          selected,
        });
      })}
    </MUIButtonGroup>
  );
};

ButtonGroup.defaultProps = {
  selectedButtonIndex: 0,
};

export default ButtonGroup;
