import {useQuery} from '@apollo/client';
import {useLocation} from '@reach/router';
import {LIST_DATA_OBJECTS} from '@macanta/graphql/dataObjects';
import isFunction from 'lodash/isFunction';

const getContactIdFromPathname = (pathname) => {
  const path = pathname.split('/');
  const contactId = path[path.findIndex((item) => item === 'data-object') - 1];

  return contactId;
};

const DataObjectsQuery = ({search, onCompleted, onError, children}) => {
  const location = useLocation();

  const {loading, data, error} = useQuery(LIST_DATA_OBJECTS, {
    variables: {
      q: search,
      contactId: getContactIdFromPathname(location.pathname),
    },
    onCompleted,
    onError,
  });

  console.log('DataObjectsQuery response', loading, data, error);

  return isFunction(children) ? children({loading, data, error}) : null;
};

export default DataObjectsQuery;
