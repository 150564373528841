import React, {useEffect} from 'react';

import * as Styled from './styles';

const DataTable = ({data, selectable, autoSelect, onSelectItem, ...props}) => {
  useEffect(() => {
    const isSingleData = data.length === 1;

    if (isSingleData && selectable && autoSelect) {
      onSelectItem(data[0]);
    }
  }, [data, selectable, autoSelect]);

  return (
    <Styled.Root
      {...props}
      data={data}
      selectable={selectable}
      onSelectItem={onSelectItem}
    />
  );
};

DataTable.defaultProps = {
  data: [],
};

export default DataTable;
