import {useCallback} from 'react';
import {useQuery} from '@apollo/client';
import {LIST_CONTACTS} from '@macanta/graphql/contacts';
import isFunction from 'lodash/isFunction';
// import * as Storage from '@macanta/utils/storage';

export const DEFAULT_PAGE = 0;
export const DEFAULT_PAGE_LIMIT = 1000;

const ContactsQuery = ({
  search,
  page = DEFAULT_PAGE,
  limit = DEFAULT_PAGE_LIMIT,
  onCompleted,
  onError,
  options = {},
  children,
}) => {
  console.log('ContactsQuery params', search, page, limit);

  const variables = {
    q: search,
    page,
    limit,
  };

  const handleCompleted = useCallback((responseData) => {
    console.log('ContactsQuery handleCompleted', responseData, {
      client,
      variables,
    });
    onCompleted && onCompleted(responseData, {client, variables});
  }, []);

  const {loading, data, error, client} = useQuery(LIST_CONTACTS, {
    variables,
    onCompleted: handleCompleted,
    onError,
    ...options,
  });

  return isFunction(children) ? children({loading, data, error}) : null;
};

export default ContactsQuery;
