import React, {useState} from 'react';
import TextField from './TextField';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import moment from 'moment';
import {useTheme} from '@material-ui/core/styles';

const convertToDate = (value, format) => {
  if (!value) {
    return null;
  } else if (typeof value === 'string') {
    return moment(value, format).toDate();
  }
  return value;
};

const DatePickerField = ({DatePickerProps, value, onChange, ...props}) => {
  const theme = useTheme();

  const {outputFormat = 'YYYY-MM-DD'} = DatePickerProps;
  const [selectedDate, setSelectedDate] = useState(
    convertToDate(value, outputFormat),
  );

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    onChange(moment(newDate).format(outputFormat));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={selectedDate}
        onChange={handleDateChange}
        renderInput={(params) => (
          <TextField {...params} {...props} helperText="" />
        )}
        OpenPickerButtonProps={{
          style: {
            color: theme.palette.secondary.main,
          },
        }}
        {...DatePickerProps}
      />
    </LocalizationProvider>
  );
};

export default DatePickerField;
