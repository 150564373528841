import {experimentalStyled as styled} from '@material-ui/core/styles';

import PaperComp from '@macanta/components/Paper';
import Box from '@material-ui/core/Box';
import MUIDivider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import LoadingIndicatorComp from '@macanta/components/LoadingIndicator';
import * as applicationStyles from '@macanta/themes/applicationStyles';

export const Root = styled(PaperComp)`
  padding: 0;
  margin: ${({theme}) => theme.spacing(3)} ${({theme}) => theme.spacing(2)} 0;
  background-color: white;
`;

export const SectionHeader = styled(Box)`
  padding: 0 ${({theme}) => theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 3.75rem;
  border-bottom: 1px solid ${({theme}) => theme.palette.primary.main};
  box-shadow: 0px 4px 10px -6px #ccc;
  z-index: 10;
`;

export const SectionBody = styled(Box)`
  flex: 1;
  position: relative;
`;

export const Divider = styled(MUIDivider)`
  background-color: ${({theme}) => theme.palette.primary.main};
`;

export const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const LoadingIndicator = styled(LoadingIndicatorComp)``;

export const Title = styled(Typography)``;

export const SectionFooter = styled(Box)`
  ${applicationStyles.footer}
`;

export const fillScroll = `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
`;

export const BodyChildren = styled(Box)`
  ${(props) => props.fullHeight && fillScroll}
`;
