import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import * as Styled from './styles';
import {IconButton} from '@macanta/components/Button';
import CloseIcon from '@material-ui/icons/Close';
import LoadingIndicator from '@macanta/components/LoadingIndicator';
import isNil from 'lodash/isNil';

const Modal = ({
  children,
  headerTitle,
  loading,
  onClose,
  contentWidth,
  contentHeight,
  innerStyle,
  disableScroll,
  ...props
}) => (
  <Styled.Root onClose={onClose} {...props}>
    {loading ? (
      <LoadingIndicator fill />
    ) : (
      <>
        <Styled.Container
          {...(!isNil(contentHeight) && {
            style: {
              height: contentHeight,
            },
          })}>
          <Styled.InnerContainer
            {...(!isNil(contentWidth) && {
              style: {
                width: contentWidth,
                ...innerStyle,
                ...(disableScroll && {overflow: 'initial'}),
              },
            })}>
            <Styled.ModalHeader>
              <Box>
                {!!headerTitle && (
                  <Typography color="grey">{headerTitle}</Typography>
                )}
              </Box>
              <IconButton aria-haspopup="true" onClick={onClose}>
                <CloseIcon style={{color: 'white'}} />
              </IconButton>
            </Styled.ModalHeader>
            <Styled.ModalBody>{children}</Styled.ModalBody>
          </Styled.InnerContainer>
        </Styled.Container>
      </>
    )}
  </Styled.Root>
);

export default Modal;
