import {gql} from '@apollo/client';

export default gql`
  query listDataObjectTypes($appName: String!, $apiKey: String!) {
    listDataObjectTypes(appName: $appName, apiKey: $apiKey) {
      id
      title
    }
  }
`;
