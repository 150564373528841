import {gql} from '@apollo/client';

export default gql`
  query listRelationships($appName: String!, $apiKey: String!, $groupId: ID!) {
    listRelationships(appName: $appName, apiKey: $apiKey, groupId: $groupId) {
      id
      role
      exclusive
      limit
      hasLimit
      autoAssignLoggedInUser
      autoAssignContact
    }
  }
`;
