import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import LoadingIndicator from '@macanta/components/LoadingIndicator';
import * as Styled from './styles';

const Search = ({
  autoFocus,
  defaultValue,
  categories,
  defaultCategory,
  suggestions,
  filterSuggestions,
  TextFieldProps,
  onSearch,
  onSelectSuggestion,
  loading,
  onChangeCategory,
  onChange,
  getOptionLabel,
  filterOptions,
  placeholder,
  disableCategories,
  disableSuggestions,
  RightComp,
  error,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);
  const [category, setCategory] = useState(defaultCategory);
  const [filteredSuggestions, setFilteredSuggestions] = useState(suggestions);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    onChangeCategory(event.target.value);
  };

  const handleSearchTextChange = (newVal) => {
    setValue(newVal);
    onChange(newVal, category);
  };

  const handleSelectSuggestion = (params) => (suggestion) => {
    console.log('handleSelectSuggestion', params, suggestion);
    setValue(suggestion);
    onSelectSuggestion({
      category: params.category || categories[0]?.value,
      value: suggestion,
    });
  };

  const handleSearch = (params) => () => {
    onSearch({
      category: params.category || categories[0]?.value,
      value: params.value,
    });
  };

  const handleCategoryRenderValue = (value) => {
    const selectedCategory = categories.find((c) => c.value === value);

    return selectedCategory.label;
  };

  useEffect(() => {
    console.log('Search suggestions', suggestions.length);
    if (!disableSuggestions) {
      let updatedFilteredSuggestions = !filterSuggestions
        ? suggestions
        : filterSuggestions(suggestions);

      if (value) {
        updatedFilteredSuggestions = updatedFilteredSuggestions.filter(
          (suggestion) => suggestion !== value,
        );

        updatedFilteredSuggestions = [value].concat(updatedFilteredSuggestions);
      }

      setFilteredSuggestions(updatedFilteredSuggestions);
    }
  }, [disableSuggestions, suggestions, value]);

  return (
    <Styled.Root {...props}>
      {!disableCategories && (
        <Styled.SearchSelect
          select
          size="small"
          value={category}
          hiddenLabel
          onChange={handleCategoryChange}
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: 0,
            },
          }}
          renderValue={handleCategoryRenderValue}>
          <MenuItem value="" disabled>
            Search by
          </MenuItem>
          {categories.map((cat) => (
            <MenuItem key={cat.value} value={cat.value}>
              {cat.label}
            </MenuItem>
          ))}
        </Styled.SearchSelect>
      )}
      <div
        style={{
          flex: 1,
          position: 'relative',
        }}>
        <Styled.SearchTextField
          autoFocus={autoFocus}
          search
          size="small"
          error={error}
          value={value}
          hiddenLabel
          variant="outlined"
          onChange={handleSearchTextChange}
          onSelectOption={handleSelectSuggestion({category})}
          placeholder={placeholder}
          AutoCompleteProps={{
            options: !disableSuggestions ? filteredSuggestions : [],
            getOptionLabel,
            filterOptions,
          }}
          onEnterPress={handleSearch({category, value})}
          TextFieldProps={TextFieldProps}
          InputProps={{
            style: {
              borderRadius: 0,
            },
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: '0.5rem',
            display: 'flex',
            alignItems: 'center',
          }}>
          {!loading ? (
            <Styled.SearchIconButton
              disableFocusRipple
              disableRipple
              onClick={handleSearch({category, value})}>
              <Styled.SearchIcon />
            </Styled.SearchIconButton>
          ) : (
            <LoadingIndicator size={20} />
          )}
        </div>
      </div>
      {RightComp}
      {/* <Styled.ActionButton
        onClick={handleSearch({category, value})}
        variant="contained">
        <SearchIcon />
      </Styled.ActionButton> */}
    </Styled.Root>
  );
};

Search.defaultProps = {
  autoFocus: false,
  defaultValue: '',
  categories: [],
  defaultCategory: '',
  loading: false,
  suggestions: [],
  onSearch: () => {},
  onChangeCategory: () => {},
  onChange: () => {},
  placeholder: 'Search...',
};

Search.propTypes = {
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  defaultCategory: PropTypes.string,
  loading: PropTypes.bool,
  onSearch: PropTypes.func,
  onChangeCategory: PropTypes.func,
  onChange: PropTypes.func,
  onSelectSuggestion: PropTypes.func,
  filterSuggestions: PropTypes.func,
  placeholder: PropTypes.string,
};

export default Search;
