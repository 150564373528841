import {experimentalStyled as styled} from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import MUIAppBar from '@material-ui/core/AppBar';

export const Root = styled(Box)`
  width: 100%;
`;

export const FlexGrow = styled(Box)`
  flex: 1;
`;

export const SearchIconContainer = styled(Box)`
  width: ${({theme}) => theme.spacing(9)};
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchInput = styled(InputBase)`
  padding-top: 0.25rem;
  padding-right: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: ${({theme}) => theme.spacing(9)};
  transition: ${({theme}) => theme.transitions.create('width')};
  width: 100%;
`;

export const SectionDesktop = styled(Box)`
  display: none;
  ${({theme}) => theme.breakpoints.up('md')} {
    display: flex;
  }
`;

export const SectionMobile = styled(Box)`
  display: flex;
  ${({theme}) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const NavIconButton = styled(IconButton)`
  padding: 12px;
`;

export const NavMobileMenuItem = styled(MenuItem)`
  display: flex;
  padding: 0 1rem;
  justify-content: flex-start;
`;

export const NavMobileIconButton = styled(IconButton)`
  margin-right: 1rem;
`;

export const AppBar = styled(MUIAppBar)`
  background-color: white;
  color: ${({theme}) => theme.palette.text.primary};
`;
