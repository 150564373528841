export const getSelectedVariantAttrs = (variant, prevStyle) => {
  let attrs = {
    variant: 'contained',
    style: {
      ...prevStyle,
      fontWeight: 'bold',
      color: 'white',
    },
  };

  console.log('getSelectedVariantAttrs', variant);

  switch (variant) {
    case 'text': {
      attrs = {
        variant: 'text',
        style: {
          fontWeight: 'bold',
        },
      };

      break;
    }
  }

  return attrs;
};
