import * as yup from 'yup';

const contactValidationSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string(),
  email: yup.string().email('Enter a valid email').required('Required'),
  company: yup.string(),
  phoneNumbers: yup.array(),
});

export default contactValidationSchema;
