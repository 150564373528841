import React, {useState, useLayoutEffect, useMemo} from 'react';
import {useNavigate} from '@reach/router';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DataObjectsQuery from '@macanta/modules/hoc/DataObjectsQuery';
import DataTable from '@macanta/containers/DataTable';
import ButtonGroup from '@macanta/components/ButtonGroup';
import Button, {IconButton} from '@macanta/components/Button';
import Tooltip from '@macanta/components/Tooltip';
import Popover from '@macanta/components/Popover';
import {FETCH_POLICIES} from '@macanta/constants/fetchPolicies';
import useDOTypes from './useDOTypes';
import useDOFields from './useDOFields';
import * as Styled from './styles';

const getDOItemsDataWithId = (doItems, navigate, onClose) => {
  const doItemsData = doItems.reduce((acc, item) => {
    const primaryConnectedContact = item.connectedContacts[0];
    const itemObj = {
      id: item.itemId,
      ID: item.itemId,
      connectedContacts: item.connectedContacts,
      'Connected Contacts': !primaryConnectedContact
        ? ''
        : [
            {
              id: primaryConnectedContact.contactId,
              label: `${primaryConnectedContact.firstName} ${primaryConnectedContact.lastName}`.trim(),
              subtext: primaryConnectedContact.relationships
                .map((r) => r.role)
                .join(', '),
              onClick: () => {
                navigate(
                  `/app/dashboard/${primaryConnectedContact.contactId}/notes`,
                );
                onClose();
              },
            },
          ],
    };

    item.data.forEach((d) => {
      itemObj[d.name] = d.value;
    });

    return acc.concat(itemObj);
  }, []);

  return doItemsData;
};

const DOColumnsWithFieldsData = ({
  groupId,
  children,
  items: doItems,
  onClose,
}) => {
  const navigate = useNavigate();
  const {loading, data} = useDOFields(groupId);

  const doFields = data?.listDataObjectFields;

  const [columns = [], doItemsData = []] = useMemo(() => {
    if (doFields) {
      const selectedFieldLength = doFields.filter((field) => field.showInTable)
        .length;
      const fieldLength =
        selectedFieldLength || (doFields.length > 3 ? 3 : doFields.length);
      const fieldsData = doFields.slice(0, fieldLength);

      const columnsWithId = [{name: 'ID'}]
        .concat(fieldsData)
        .concat({
          name: 'Connected Contacts',
          minWidth: 200,
        })
        .map((field) => ({
          id: field.name,
          label: field.name,
          subtext: field.subtext,
          minWidth: field.minWidth || 170,
        }));
      const doItemsDataWithId = getDOItemsDataWithId(
        doItems,
        navigate,
        onClose,
      );

      return [columnsWithId, doItemsDataWithId];
    }

    return [];
  }, [doFields, doItems]);

  return children({columns, doItemsData, loading});
};

const DOTypeWithFields = ({type, items: doItems = [], ...props}) => {
  const {data} = useDOTypes({
    fetchPolicy: FETCH_POLICIES.CACHE_FIRST,
  });

  const doTypes = data?.listDataObjectTypes;

  const selectedDOType = doTypes?.find(({title}) => title === type);

  return doItems.length > 0 && selectedDOType ? (
    <DOColumnsWithFieldsData
      groupId={selectedDOType.id}
      items={doItems}
      {...props}
    />
  ) : (
    props.children({columns: [], doItemsData: []})
  );
};

const DOTypeSelectTable = ({
  loading: doLoading,
  dataObjects = [],
  children,
  ...props
}) => {
  const [selectedDO, setSelectedDO] = useState(dataObjects[0]);

  const handleSelectDOType = (type) => () => {
    const newSelectedDO = dataObjects.find((d) => d.type === type);

    setSelectedDO(newSelectedDO);
  };

  useLayoutEffect(() => {
    if (dataObjects) {
      handleSelectDOType(selectedDO?.type || dataObjects[0]?.type)();
    }
  }, [dataObjects]);

  return (
    <DOTypeWithFields
      loading={doLoading}
      type={selectedDO?.type}
      items={selectedDO?.items}
      {...props}>
      {({columns, doItemsData, loading}) => {
        return (
          <>
            {dataObjects.length > 0 && (
              <Styled.DOTypeButtonGroupContainer>
                <ButtonGroup
                  size="medium"
                  variant="outlined"
                  aria-label="primary button group">
                  {dataObjects.map(({type, items}, index) => {
                    return (
                      <Button key={index} onClick={handleSelectDOType(type)}>
                        {type} ({items.length})
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Styled.DOTypeButtonGroupContainer>
            )}
            {children({columns, doItemsData, loading})}
          </>
        );
      }}
    </DOTypeWithFields>
  );
};

const DOActionButtons = ({item, columns: prioritizedColumns, onClose}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(false);

  const openOtherDetails = Boolean(anchorEl);

  const handleViewOtherDetails = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditItem = () => {};

  const handleCloseOtherDetails = () => {
    setAnchorEl(null);
  };

  const [columns = [], otherDetails = []] = useMemo(() => {
    const otherDetailsData = Object.entries(item).reduce(
      (acc, [key, value]) => {
        const isPrioritizedCol = prioritizedColumns.some((col) =>
          ['id', 'ID', 'connectedContacts', col.subtext].includes(key),
        );

        if (key === 'Connected Contacts') {
          return acc.concat({
            id: key,
            Key: key,
            Value: item.connectedContacts.map((contact) => ({
              id: contact.contactId,
              label: `${contact.firstName} ${contact.lastName}`.trim(),
              subtext: contact.relationships.map((r) => r.role).join(', '),
              onClick: () => {
                navigate(`/app/dashboard/${contact.contactId}/notes`);
                onClose();
              },
            })),
          });
        }

        if (!isPrioritizedCol) {
          return acc.concat({
            id: key,
            Key: key,
            Value: value || 'N/A',
          });
        }

        return acc;
      },
      [],
    );

    const otherDetailColumns = [
      {
        id: 'Key',
        label: 'Key',
        width: 200,
        textStyle: {
          fontWeight: 'bold',
        },
      },
      {
        id: 'Value',
        label: 'Value',
        minWidth: 170,
        maxWidth: '100%',
      },
    ];

    return [otherDetailColumns, otherDetailsData];
  }, []);

  return (
    <Styled.ActionButtons openOtherDetails={openOtherDetails}>
      <Tooltip title="Show More">
        <Styled.ActionButtonContainer>
          <Styled.ActionButton onClick={handleViewOtherDetails} size="small">
            <VisibilityIcon />
          </Styled.ActionButton>
        </Styled.ActionButtonContainer>
      </Tooltip>
      <Tooltip title="Edit">
        <Styled.ActionButtonContainer>
          <Styled.ActionButton onClick={handleEditItem} size="small">
            <EditIcon />
          </Styled.ActionButton>
        </Styled.ActionButtonContainer>
      </Tooltip>
      <Popover
        open={openOtherDetails}
        anchorEl={anchorEl}
        onClose={handleCloseOtherDetails}
        anchorOrigin={{
          vertical: 'center',
          horizontal: -8,
        }}
        transformOrigin={{
          vertical: 100,
          horizontal: 'right',
        }}>
        <div style={{minWidth: 500, maxWidth: 600}}>
          <Styled.PopoverHeader>
            <Typography color="grey" fontWeight="bold">
              {item.id}
            </Typography>
            <IconButton onClick={handleCloseOtherDetails}>
              <CloseIcon style={{color: 'white'}} />
            </IconButton>
          </Styled.PopoverHeader>

          <DataTable
            columns={columns}
            data={otherDetails}
            disableLineClamp
            hideColumns
            hidePagination
            rowsPerPage={otherDetails.length}
            containerStyle={{
              maxHeight: 'unset',
            }}
          />
        </div>
      </Popover>
    </Styled.ActionButtons>
  );
};

const DataObjectsSearchTable = ({
  searchQuery,
  columns: selectedColumns,
  onCloseModal: onClose,
  ...props
}) => {
  console.log('DataObjectsSearchTable', searchQuery, props);

  return (
    <DataObjectsQuery {...searchQuery}>
      {({data = {}, loading: doLoading}) => {
        const dataObjects = data?.listDataObjects?.items;

        return (
          <DOTypeSelectTable
            loading={doLoading}
            dataObjects={dataObjects}
            onClose={onClose}>
            {({columns, doItemsData, loading: columnsLoading}) => {
              const loading = Boolean(doLoading || columnsLoading);

              console.log(
                'DO search columns & doItemsData',
                doLoading,
                columnsLoading,
                columns,
                doItemsData,
              );

              return (
                <DataTable
                  fullHeight
                  loading={loading}
                  hideEmptyMessage={loading}
                  columns={selectedColumns || columns}
                  data={doItemsData}
                  ActionButtonsComp={(actionBtnProps) => (
                    <DOActionButtons {...actionBtnProps} onClose={onClose} />
                  )}
                  numOfTextLines={2}
                  {...props}
                />
              );
            }}
          </DOTypeSelectTable>
        );
      }}
    </DataObjectsQuery>
  );
};

DataObjectsSearchTable.defaultProps = {};

export default DataObjectsSearchTable;
