import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as Styled from './styles';

const ExpandItem = ({expanded, onClick}) => {
  return (
    <ListItem button onClick={onClick} disableRipple>
      <ListItemText primary="" />
      <Styled.ExpandIcon expanded={expanded} />
    </ListItem>
  );
};

export default ExpandItem;
