import {experimentalStyled as styled} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MUITable from '@material-ui/core/Table';
import MUITableContainer from '@material-ui/core/TableContainer';
import MUITableRow from '@material-ui/core/TableRow';
import MUITableHead from '@material-ui/core/TableHead';
import MUITableCell from '@material-ui/core/TableCell';
import MUITableSortLabel from '@material-ui/core/TableSortLabel';
import SortIconComp from '@macanta/themes/icons/Sort';
import * as applicationStyles from '@macanta/themes/applicationStyles';

export const EmptyMessageContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EmptyMessage = styled(Typography)``;

export const Root = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const RootContainer = styled(Box)`
  flex: 1;
  position: relative;
`;

export const TableContainer = styled(MUITableContainer)``;

export const Table = styled(MUITable)`
  transform: translate3d(0, 0, 0);
`;

export const TableHead = styled(MUITableHead)`
  & > * {
    user-select: none;
  }
`;

export const TableHeadRow = styled(MUITableRow)`
  height: ${applicationStyles.DEFAULT_HEAD_ROW_HEIGHT}px;
`;

export const TableBodyRow = styled(MUITableRow)`
  ${({index}) => index % 2 !== 0 && 'background-color: #fcfcfc;'}
  ${({selectable}) => selectable && `cursor: pointer;`}

  & .actionButtonCol > * {
    opacity: 0;
  }

  &:hover .actionButtonCol > * {
    opacity: 1;
  }
`;

export const TableHeadCell = styled(MUITableCell)`
  background-color: #f5f6fa;
`;

export const TableBodyCell = styled(MUITableCell)`
  vertical-align: top;
`;

export const TableActionBodyCell = styled(TableBodyCell)`
  vertical-align: middle;
`;

export const TableCellText = styled(Typography)`
  font-size: 14px;
`;

export const TableHeadCellText = styled(TableCellText)`
  ${applicationStyles.oneLineText}
`;

export const TableBodyCellLabel = styled(TableCellText)`
  ${({disableLineClamp, lines}) =>
    !disableLineClamp && applicationStyles.getLineText(lines)}
  line-height: 24px;
`;

export const Subtext = styled(Typography)`
  ${applicationStyles.oneLineText}
  font-size: 12px;
  color: #aaa;
`;

export const TableSortLabel = styled(MUITableSortLabel)``;

export const TableFooter = styled(Box)`
  ${applicationStyles.footer}
`;

export const SortIcon = styled(SortIconComp)`
  opacity: 0.1;
  font-size: 12px;
  margin-left: 0.5rem;
  fill: currentColor;

  ${({active}) => active && `fill: unset; color: #2196f3!important;`}
`;

export const ColumnResizerContainer = styled(Box)`
  cursor: col-resize;
  padding: 0 0.5rem;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  ${'' /* prevents from scrolling while dragging on touch devices */}
  touch-action:none;
  transform: translateX(50%);
  display: flex;
  align-items: center;

  &:hover > * {
    background-color: ${({theme}) => theme.palette.primary.main};
    width: 2px;
  }
`;

export const ColumnResizerChild = styled(Box)`
  background-color: #bbb;
  width: 1px;
  height: 28px;
`;
