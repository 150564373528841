import {gql} from '@apollo/client';

export default gql`
  mutation uploadDOAttachments(
    $uploadDOAttachmentsInput: UploadDONoteAttachmentsInput!
  ) {
    uploadDOAttachments(input: $uploadDOAttachmentsInput) {
      id
    }
  }
`;
