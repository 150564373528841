import {experimentalStyled as styled} from '@material-ui/core/styles';
import MUITextField from '@macanta/components/TextField';
import Box from '@material-ui/core/Box';
import Button from '@macanta/components/Button';

export const Root = styled(Box)``;

export const TextField = styled(MUITextField)`
  margin-bottom: ${({theme}) => theme.spacing(2)};
`;

export const FormGroup = styled(Box)`
  margin: ${({theme}) => theme.spacing(2)} 0px;
  padding: 0px ${({theme}) => theme.spacing(2)};
`;

export const FormContainer = styled(Box)``;

export const Footer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: ${({theme}) => theme.spacing(2)} 0px;
`;

export const FooterButton = styled(Button)`
  margin-right: ${({theme}) => theme.spacing(2)};
`;
