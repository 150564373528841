import {experimentalStyled as styled} from '@material-ui/core/styles';

import Page from '@macanta/components/Page';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import * as applicationStyles from '@macanta/themes/applicationStyles';

export const Root = styled(Page)`
  flex-direction: row;
`;

export const FullHeightGrid = styled(Grid)`
  height: 100%;
`;

export const FullHeightFlexGridColumn = styled(Grid)`
  ${applicationStyles.fullHeightFlexColumn}
`;

export const FullWidthFlexContainer = styled(Box)`
  flex-grow: 1;
  ${applicationStyles.fullHeightFlexColumn}
`;
