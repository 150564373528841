import {gql} from '@apollo/client';

export default gql`
  query getAccessPermissions(
    $appName: String!
    $apiKey: String!
    $email: String!
  ) {
    getAccessPermissions(appName: $appName, apiKey: $apiKey, email: $email) {
      level
      sections {
        groupId
        access {
          name
          permission
        }
      }
      contacts {
        groupId
        access {
          name
          permission
        }
      }
    }
  }
`;
