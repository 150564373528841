import {gql} from '@apollo/client';
import {CONTACT_ATTRIBUTES} from './fragments';

export default gql`
  mutation createOrUpdateContact(
    $createOrUpdateContactInput: CreateOrUpdateContactInput!
  ) {
    createOrUpdateContact(input: $createOrUpdateContactInput) {
      ...contactResults
    }
  }
  ${CONTACT_ATTRIBUTES}
`;
