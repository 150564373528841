import {gql} from '@apollo/client';

export default gql`
  query listDataObjectFields(
    $appName: String!
    $apiKey: String!
    $groupId: ID!
  ) {
    listDataObjectFields(
      appName: $appName
      apiKey: $apiKey
      groupId: $groupId
    ) {
      id
      name
      type
      helpText
      placeholder
      default
      required
      subGroup
      sectionTag
      showInTable
      showOrder
      choices
    }
  }
`;
