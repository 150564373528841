import React from 'react';

import TextField from './TextField';
import isEmpty from 'lodash/isEmpty';
import Typography from '@material-ui/core/Typography';

const SelectField = ({multiple, renderValue, placeholder, ...props}) => {
  const handleRenderValue = (selected) => {
    let displayedText = placeholder || 'Select...';

    if (!isEmpty(selected)) {
      if (!renderValue) {
        return selected;
      } else {
        return renderValue(selected);
      }
    } else {
      return (
        <Typography
          style={{
            color: '#aaa',
          }}>
          {displayedText}
        </Typography>
      );
    }
  };

  return (
    <TextField
      SelectProps={{
        multiple,
        displayEmpty: !!props.hiddenLabel,
        renderValue: handleRenderValue,
      }}
      {...props}
      select
    />
  );
};

export default SelectField;
