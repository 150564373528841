import React, {useCallback, useState} from 'react';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Modal from '@macanta/components/Modal';
import ContactForms from '@macanta/modules/ContactForms';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@macanta/components/Button';
import * as HeaderStyled from '@macanta/containers/Header/styles';

const ContactFormsIconBtn = ({mobile, item, onSaveContact}) => {
  const isEdit = !!item;
  const [showModal, setShowModal] = useState(false);
  const handleAddContact = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const onSuccess = useCallback((contact) => {
    onSaveContact && onSaveContact(contact);
    setShowModal(false);
  }, []);

  const root = isEdit ? (
    <Button
      onClick={handleAddContact}
      size="small"
      variant="contained"
      startIcon={<EditIcon />}>
      Edit Contact Details
    </Button>
  ) : (
    <>
      {mobile ? (
        <HeaderStyled.NavMobileMenuItem onClick={handleAddContact}>
          <HeaderStyled.NavMobileIconButton>
            <PersonAddIcon />
          </HeaderStyled.NavMobileIconButton>
          <p>Add Contact</p>
        </HeaderStyled.NavMobileMenuItem>
      ) : (
        <HeaderStyled.NavIconButton onClick={handleAddContact}>
          <PersonAddIcon />
        </HeaderStyled.NavIconButton>
      )}
    </>
  );

  return (
    <>
      {root}
      <Modal
        headerTitle="Add Contact"
        open={showModal}
        onClose={handleCloseModal}>
        <ContactForms contact={item} onSuccess={onSuccess} />
      </Modal>
    </>
  );
};

export default ContactFormsIconBtn;
