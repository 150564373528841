import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Theme from '@macanta/containers/Theme';
import {GET_APP_SETTINGS} from '@macanta/graphql/admin';
import {useQuery} from '@apollo/client';
import LoadingIndicator from '@macanta/components/LoadingIndicator';

const BaseTheme = ({children, ...props}) => {
  const appSettingsQuery = useQuery(GET_APP_SETTINGS, {});

  if (!appSettingsQuery.data?.getAppSettings) {
    return <LoadingIndicator modal loading={true} />;
  }

  return (
    <Theme appSettings={appSettingsQuery.data.getAppSettings} {...props}>
      <CssBaseline />
      {React.cloneElement(children, {style: {height: '100%'}})}
    </Theme>
  );
};

export default BaseTheme;
