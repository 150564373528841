import React from 'react';

import DashboardHeader from './DashboardHeader';
import {experimentalStyled as styled} from '@material-ui/core/styles';
import UniversalSearchComp from '@macanta/modules/UniversalSearch';
import {fade} from '@material-ui/core/styles';
import {useNavigate} from '@reach/router';
import {DEFAULT_SELECTED_TAB} from './DashboardBody/SidebarAccess/SidebarAccess';
import * as Styled from './styles';
import * as DrawerStyled from '@macanta/containers/Drawer/styles';

export const UniversalSearch = styled(UniversalSearchComp)`
  position: relative;
  border-radius: ${({theme}) => theme.shape.borderRadius};
  background-color: ${({theme}) => fade(theme.palette.common.white, 0.15)};
  &:hover {
    background-color: ${({theme}) => fade(theme.palette.common.white, 0.25)};
  }
  margin-right: ${({theme}) => theme.spacing(2)};
  width: 100%;
  ${({theme}) => theme.breakpoints.up('sm')} {
    max-width: 428px;
    margin-right: ${({theme}) => theme.spacing(4)};
  }
`;

const DashboardPage = ({children}) => {
  const navigate = useNavigate();

  const handleSelectContact = (selectedContact) => {
    console.log('handleSelectContact', selectedContact);
    navigate(`/app/dashboard/${selectedContact.id}/${DEFAULT_SELECTED_TAB}`);
  };

  return (
    <Styled.Root>
      <DashboardHeader onSaveContact={handleSelectContact}>
        <UniversalSearch modal autoSelect onSelectItem={handleSelectContact} />
      </DashboardHeader>
      <Styled.FullWidthFlexContainer>
        <DrawerStyled.ContentToolbarSpacer />
        {children}
      </Styled.FullWidthFlexContainer>
    </Styled.Root>
  );
};

export default DashboardPage;
