import React from 'react';
import Section from './Section';
import SubSection from './SubSection';

const IndexSection = ({subsection, ...props}) => {
  if (subsection) {
    return <SubSection {...props} />;
  }

  return <Section {...props} />;
};

export default IndexSection;

export {Section, SubSection};
