import {gql} from '@apollo/client';

export const CONTACT_ATTRIBUTES = gql`
  fragment contactResults on Contact {
    id
    email
    firstName
    middleName
    lastName
    birthday
    title
    company
    emailAddress2
    emailAddress3
    website
    jobTitle
    phoneNumbers
    streetAddress1
    streetAddress2
    city
    state
    postalCode
    country
    address2Street1
    address2Street2
    city2
    state2
    postalCode2
    country2
    isLoggedInUserAllowedToEdit
    createdDate
  }
`;
