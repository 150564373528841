import React, {useState} from 'react';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import {makeStyles} from '@material-ui/core/styles';
import Drawer, {
  drawerWidth,
  getDrawerClosedWidth,
} from '@macanta/containers/Drawer';
import * as Styled from './styles';

//TODO: remove !important overrides if fixed on next MUI version
const useStyles = makeStyles((theme) => ({
  appBar: {
    // zIndex: `${theme.zIndex.drawer + 1}!important`,
    marginLeft: getDrawerClosedWidth(theme),
    width: `calc(100% - ${getDrawerClosedWidth(theme)})!important`,
    transition: `${theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })}!important`,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)!important`,
    transition: `${theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })}!important`,
  },
  toolbar: {
    padding: '0 1rem!important',
  },
}));

const Header = ({children, renderDesktopMenu, renderMobileMenu}) => {
  const classes = useStyles();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  return (
    <>
      <Styled.AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}>
        <Toolbar className={classes.toolbar}>
          {children}
          <Styled.FlexGrow />
          <Styled.SectionDesktop>{renderDesktopMenu}</Styled.SectionDesktop>
          <Styled.SectionMobile>
            <IconButton aria-haspopup="true" onClick={handleMobileMenuOpen}>
              <MoreIcon />
            </IconButton>
          </Styled.SectionMobile>
        </Toolbar>
      </Styled.AppBar>
      <Drawer drawerOpen={drawerOpen} onDrawerToggle={handleDrawerToggle} />
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}>
        {renderMobileMenu}
      </Menu>
    </>
  );
};

export default Header;
