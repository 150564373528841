const WHITE = '#FFFFFF';
const BLACK = '#000000';

const colors = {
  primary: '#663399',
  secondary: '#9B78BB',
  tertiary: '#AF93CA',
  error: '#d50000',
  background: '#FAFAFA',
  gray: '#F5F6FA',
  white: WHITE,
  black: BLACK,
};

export default colors;
