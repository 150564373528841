import React from 'react';
import {experimentalStyled as styled} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import MUIListItem from '@material-ui/core/ListItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import * as applicationStyles from '@macanta/themes/applicationStyles';

// necessary for content to be below app bar
export const ContentToolbarSpacer = styled(Box)`
  ${({expanded}) =>
    !expanded
      ? applicationStyles.flexCenter
      : `
      ${applicationStyles.rowCenterJustifyStart}
      padding: 0 1rem;
      `}
  ${({theme}) => theme.mixins.toolbar}
`;

export const DrawerContent = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const BottomList = styled(List)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ListItem = styled(MUIListItem)`
  ${({selected, theme}) =>
    selected &&
    `
    & .MuiListItemIcon-root {
      color: ${theme.palette.primary.main};
    }
  `}
`;

export const ExpandIcon = styled(({expanded, ...props}) =>
  expanded ? <ChevronLeftIcon {...props} /> : <ChevronRightIcon {...props} />,
)`
  font-size: 1.75rem;
`;

export const CompanyNameContainer = styled(Box)`
  flex: 1;
  ${applicationStyles.flexCenter}
`;

export const CompanyName = styled(Typography)``;
