import {gql} from '@apollo/client';

export default gql`
  query listDataObjectItems(
    $appName: String!
    $apiKey: String!
    $email: String
    $groupId: ID!
    $contactId: ID!
  ) {
    listDataObjectItems(
      appName: $appName
      apiKey: $apiKey
      email: $email
      groupId: $groupId
      contactId: $contactId
    ) {
      items {
        id
        data {
          fieldId
          name
          type
          value
          subGroup
          sectionTag
        }
        attachments {
          id
          fileName
          thumbnail
          downloadUrl
        }
        notes {
          id
          accepted
          userId
          creationDate
          completionDate
          lastUpdated
          lastUpdatedBy
          endDate
          type
          actionDate
          title
          noteType
          note
          tags
        }
        meta {
          editable
          searchable
          multiple_link
        }
        connectedContacts {
          contactId
          firstName
          lastName
          email
          relationships {
            id
            role
          }
        }
      }
      count
      next
      previous
    }
  }
`;
